import { Divider } from "@material-ui/core";
import { AddLocation, Call, Email,Facebook,Twitter } from "@material-ui/icons";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import {colors,API_URL} from "../res/values/values"

const Container = styled.div`
  color:${colors.whiteColor};
  background-color:${colors.primaryColor};
  width: 100%;
`;
const Top = styled.div`
  padding: 10px;
  display: flex;
  margin: 0 5px;
  justify-content: flex-start;
  flex-direction:column ;
  @media only screen and (min-width: 600px) {
    flex-direction: row;
    margin: 0 40px;
  }

`;
const ColumnList = styled.ul`
  list-style-type: none;
  margin-left: 10px;
  margin-right:10px ;
  padding: 0;
  cursor: pointer;
`;
const ColumnListTitle = styled.li`
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 10px;
  letter-spacing: 1px;
`;
const ColumnListItem = styled.li`
  margin-bottom: 5px;
  letter-spacing: 1px;
  display: flex;
`;
const Bottom = styled.div`
    padding:5px ;
    background-color: ${colors.blackColor};
   
  & p {
    margin: 8px 0;
    color: ${colors.whiteColor};
    font-size: 0.8rem;
    & a {
      color: ${colors.whiteColor};
      font-size: 0.8rem;
    }
  }
`;
function FooterMaintenace() {
  const navigate = useNavigate();
  return (
    <Container>
      <Top>
        <ColumnList>
          <ColumnListTitle>Contact Us</ColumnListTitle>
          <ColumnListItem>
            <AddLocation style={{ marginRight: "5px" }} />
            The Grid,Unit 102 ,Lockfield Avenue  ENFIELD,EN3 7PX,UNITED KINGDOM.
          </ColumnListItem>
          <ColumnListItem>
            <Call style={{ marginRight: "5px" }} />
            0208 0667155
          </ColumnListItem>
          <ColumnListItem>
            <Email style={{ marginRight: "5px" }} />
            info@6868.co.uk
          </ColumnListItem>
        </ColumnList>
        <ColumnList>
          <ColumnListTitle>Follow Us</ColumnListTitle>
          <ColumnListItem>
            <Facebook style={{ marginRight: "5px" }} />
            Facebook
          </ColumnListItem>
          <ColumnListItem>
            <Twitter style={{ marginRight: "5px" }} />
            Twitter
          </ColumnListItem>
          
        </ColumnList>
        <ColumnList>
          <ColumnListTitle >About Us</ColumnListTitle>
          <ColumnListItem>
            Contact
          </ColumnListItem>
          <ColumnListItem>
            About
          </ColumnListItem>
        </ColumnList>
      </Top>
      <Divider style={{ margin: "2px 0" }} />

      <Bottom>
      <p>Powered By <a href="http://www.orcabs.com">Orca Business Solutions Ltd.  -  </a> 6868 Food Services. © 2022</p>
      </Bottom>
      <Divider style={{ margin: "2px 0" }} />
    </Container>
  );
}

export default FooterMaintenace;
