export const colors={
    primaryColor:'#013668',
    whiteColor:'#f2f2f2',
    lightcolor:'#a1a1a1',
    darkcolor:'#001b35',
    blackColor:'#e72106',
    salecolor:'#3f793e',
    weboffercolor:'#CD1B1B',
    errorcolor:'#CD1B1B'
}
export const STRIPE_KEY="pk_test_51L3ElqA2ViOJ9APC3pEByGMiNNF0Ir9FqKERDjIJyz9hw4nv3wOLoOX2w4MqOnDPBW4YdpXnF1ei3kf14nHB76MS00vJ9XmKRe"

//LIVE API
export const API_URL='https://www.tushcin.com:3000/api'
// https://www.tushcin.com:3000/api 
// TEST API 
//export const API_URL='http://77.68.32.1:3001/api'
//#e72106
//#013668
//1e54aa -- expo mid color
//008080 -- orca color