// eslint-disable-next-line import/no-anonymous-default-export
export default {
    currentCategory:{},
    categories:[],
    products:[],
    cart:[],
    search:{},
    allProducts:[],
    currentUser:{},
    loginError:"",
    productCount:0,
    settings:{},
    manufacturerList:[],
    countryList:[],
    
}