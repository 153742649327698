/* eslint-disable jsx-a11y/no-distracting-elements */
import styled from "styled-components";
import { AddLocation, LocalOffer, Instagram,Facebook,Mail, Email, Phone, Info, InfoOutlined, EmailOutlined, AccountBoxOutlined, AccountCircleOutlined, ShoppingBasketOutlined } from "@material-ui/icons";
import { colors } from "../res/values/values";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as cartActions from "./../redux/actions/cartActions";
import { useEffect } from "react";
const Container = styled.div`

  display: flex;
  flex:1 ;
  flex-direction:row;
  background-color: ${colors.darkcolor};
  color: ${colors.whiteColor};
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  min-height: 2rem;
`;

const Row = styled.div`
  font-size: .85rem;
  max-width: 1280px;
  font-weight: 500;
  letter-spacing: 1px;
  display: flex;
  flex-direction:row ;
  padding-inline: 1.5rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  @media only screen and (max-width: 969px) {
    flex-direction: column;
  }
`;
const RowElemet = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: center;
  @media only screen and (max-width: 969px) {
    justify-content: flex-start;
    margin-bottom: 0.5rem;
  }
  
`;
const RowElement1 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & p {
    color: #90B8ED;
    font-family: 'Roboto';
    font-size: 0.775rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    
    @media only screen and (max-width: 969px) {
      font-size: 0.675rem;
    }
  }
`
const RowElement = styled.div`
  margin-left: 0.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & p {
    color: #90B8ED;
    font-family: 'Roboto';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media only screen and (max-width: 969px) {
      font-weight: 400;
    }
  }
`;
const RowElementA = styled.a`
  margin-left: 0.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  color: #F2F8FF;
  font-family: 'Roboto';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media only screen and (max-width: 969px) {
    font-weight: 400;
  }
`;
const Divider = styled.div`
  border-left: 2px solid white;
  opacity: 0.6;
  margin-left: 5px;
`

function Announcement(props) {
  useEffect(()=>{},[ props.currentUser,props.cart ])
  const navigate = useNavigate()
  const handleLinkClick = (event, route) => {
    event.preventDefault();
    //window.location.href=route;
    navigate(route);
  };
  const getTotalIncVatAmount = () => {
    let totalAmountInclVat = 0;
    props.cart.map((item) => (totalAmountInclVat += item.lineamountinclvat));
    return totalAmountInclVat.toFixed(2);
  };
  return (
    <Container>
      <Row>
        <RowElemet>
          <RowElement1>
          <EmailOutlined style={{height:"25px", width:"25px",padding:"5px"}}/>
           <p>info@6868.co.uk</p>
          </RowElement1>
          <RowElement1>
          <Phone style={{height:"25px", width:"25px",padding:"5px",marginLeft:"10px"}}/>
           <p>0208 0667155</p>
          </RowElement1>
        </RowElemet>
        <RowElemet>
          
          <RowElementA
          
          href={"/route=about"}
          onClick={(event) => handleLinkClick(event, '/route=about')}
          title={"about"}>
            <InfoOutlined style={{height:"25px", width:"25px",padding:"5px"}}/><p>About</p>
          </RowElementA>
          <RowElementA
          
          //href={"/route=account/login"}
          onClick={(event) => {
            console.log('props.currentUser : ', props.currentUser);
            !props.currentUser.id ? handleLinkClick(event, '/route=account/login') : handleLinkClick(event, '/route=account/edit');
            
          }}
          title={"about"}>
            <AccountCircleOutlined style={{height:"25px", width:"25px",padding:"5px"}}/><p>Account</p>
          </RowElementA>
          <RowElementA
          
          href={"/route=checkout/cart"}
          onClick={(event) => handleLinkClick(event, '/route=checkout/cart')}
          title={"about"}>
            <ShoppingBasketOutlined style={{height:"25px", width:"25px",padding:"5px"}}/><p>£ {getTotalIncVatAmount()}</p>
          </RowElementA>
        </RowElemet>
      </Row>     
    </Container>
  );
}
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getCartList: bindActionCreators(cartActions.getCartList, dispatch),
    },
  };
}
function mapStateToProps(state) {
  return {
    cart: state.cartActionReducer,
    currentUser: state.currentUserReducer,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Announcement);