import { Button, ButtonGroup } from "@material-ui/core";
import {
  Add,
  Remove,
  ShoppingBasketOutlined,
  ShoppingCartOutlined,
} from "@material-ui/icons";
import styled from "styled-components";
import { useState, useEffect, useRef } from "react";
import { colors } from "../../res/values/values";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as cartActions from "../../redux/actions/cartActions";
import * as productActions from "../../redux/actions/productActions";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import OrcaModal from "../Modal/OrcaModal";
import {
  capitalizeFirstLetter,
  checkBlockedUnit,
  initialUnit,
  checkDeliveryDate,
} from "../../utilities/helpers";
import Carousel from "../Carousel";
import ImageSlider from "../ImageSlider";
//001 ++ Ürünlere favori seçimi eklenerek headera favoriler sekmesi eklenecek.
import FavoriteIcon from "@mui/icons-material/FavoriteBorder";
import SelectedFavoriteIcon from "@mui/icons-material/Favorite";
//001 -- Ürünlere favori seçimi eklenerek headera favoriler sekmesi eklenecek.
//#region styles

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  transition: all 0.2s ease;
`;
const DDiv = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;
const Row = styled.div`
  //border: 1px solid lightgray;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  //padding: 10px;
  margin-top: 20px;
  cursor: pointer;
  @media only screen and (min-width: 767px) {
    flex-direction: row;
  }
`;
const CardRow = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
  @media only screen and (min-width: 767px) {
    flex-direction: row;
  }
`;
const CardRowDiv1 = styled.div`
  display: flex;
  flex: 4.5;
  flex-direction: column;
  @media only screen and (min-width: 767px) {
    flex-direction: row;
  }
`;
const CardRowDiv2 = styled.div`
  display: flex;
  flex: 1;
  margin-left: 1rem;
  @media only screen and (max-width: 767px) {
    visibility: hidden;
    height: 0;
    width: 0;
  }
`;

const Image = styled.img`
  height: 22rem;
  object-fit: contain;
  padding: 1vw;
  border-width: 1px;
  border-color: lightgray;
  border-style: solid;
  @media only screen and (min-width: 768px) {
    width: 35%;
  }
`;

const Details = styled.div`
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  //flex: 1;
  margin-left: 1vw;
  width: 100%;
  @media only screen and (min-width: 768px) {
    text-align: start;
    align-items: flex-start;
  }
`;

const Title = styled.span`
  font-size: 1.1rem;
  color: ${colors.darkcolor};
  font-weight: 600;
  padding-inline: 5px;
  margin-bottom: 10px;
  margin-top: 10px;
  @media only screen and (min-width: 768px) {
    font-size: 1.5rem;
    margin-top: 0px;
  }
`;
const SubTitle = styled.span`
  font-size: 0.9rem;
  color: #949494;
  font-weight: 500;
  padding-inline: 5px;
  margin-bottom: 10px;
  @media only screen and (max-width: 768px) {
    font-size: 0.7rem;
    padding-inline: 1.5rem;
  }
`;
const Text = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 0.5rem;

  & h3 {
    font-size: 1rem;
    font-weight: 600;
    color: #2d68b7;
    margin-right: 0.5rem;
    margin-left: 10px;
    @media only screen and (max-width: 768px) {
      font-size: 0.8rem;
    }
  }
  & span {
    font-size: 1rem;
    font-weight: 400;
    color: ${colors.darkcolor};
    @media only screen and (max-width: 768px) {
      font-size: 0.8rem;
    }
  }
`;
const BreadCrumbText = styled.a`
  font-size: 0.8rem;
  font-weight: 500;
  color: ${colors.primaryColor};
  padding: 5px;
  border-width: 0px 0px 1px 0px;
  border-color: lightgray;
  border-style: solid;
  @media only screen and (min-width: 768px) {
    border-width: 0px 1px 0px 0px;
    padding: 2px;
    margin-right: 10px;
    padding-right: 10px;
  }
`;

const Price = styled.h4`
  margin-top: 10px;
  color: ${colors.primaryColor};
  padding: 5px;
  font-size: 1.3rem;
  @media only screen and (min-width: 768px) {
    font-size: 1.5rem;
    margin-left: 10px;
    text-align: left;
  }
`;
const PriceDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
const PriceDiv2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;
const ColPrice = styled.span`
  text-align: left;
  padding: 1px;
  font-size: 1.1em;
  font-weight: ${(props) =>
    props.deliveryMethod === "Collection" ? 600 : 500};
  color: ${(props) =>
    props.deliveryMethod === "Collection"
      ? colors.primaryColor
      : props.deliveryMethod === ""
      ? colors.primaryColor
      : "#636363"};
  @media only screen and (max-width: 768px) {
    font-size: 1em;
  }
`;
const DelPrice = styled.span`
  text-align: left;
  padding: 1px;
  font-size: 1.1em;
  font-weight: ${(props) => (props.deliveryMethod === "Delivery" ? 600 : 500)};
  color: ${(props) =>
    props.deliveryMethod === "Delivery"
      ? colors.primaryColor
      : props.deliveryMethod === ""
      ? colors.primaryColor
      : "#636363"};
  @media only screen and (max-width: 768px) {
    font-size: 1em;
  }
`;
const OldPriceListView = styled.h4`
  text-align: left;
  padding: 5px;
  font-size: 0.8em;
  font-weight: 500;
  color: #636363;
  text-decoration: line-through 2px #979797;
`;
const ProductInfo = styled.div`
  font-weight: 700;
  font-size: 0.7rem;
  position: absolute;
  margin: 0.1rem;

  z-index: 4;
  text-align: center;
  box-shadow: ${(props) => props.type && "1px 1px rgba(0, 0, 0, 0.3)"};
  width: 3.2rem;
  height: 3.2rem;
  line-height: 3.2rem;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: ${(props) =>
    props.type === "" ? "transparent" : colors.whiteColor};
  color: ${(props) =>
    props.type === ""
      ? "transparent"
      : props.type === "sale"
      ? colors.salecolor
      : colors.weboffercolor};
`;
const CartActions = styled.div`
  display: flex;
  flex-direction: row;
  width: 95%;
  padding: 10px;
  height: 2.5rem;
`;

const QtyInput = styled.input`
  border: 1px solid lightgray;
  font-weight: 600;
  border-radius: 2px;
  margin-right: 5px;
  font-size: 0.9rem;
  color: ${colors.darkcolor};
  text-align: center;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
  @media only screen and (min-width: 768px) {
    padding-left: 5px;
    margin-left: 1vw;
    font-size: 1.1rem;
  }
`;
const AddToCartButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 2px;
  color: white;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: ${colors.errorcolor};
  width: 25%;
  cursor: pointer;
  & h3 {
    font-size: 1rem;
    font-weight: 500;
    color: white;
    margin-right: 0.5rem;
    margin-left: 10px;
    @media only screen and (max-width: 768px) {
      font-size: 0.8rem;
    }
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
  @media only screen and (min-width: 768px) {
    padding-left: 5px;
    margin-left: 1vw;
    font-size: 1.1rem;
  }
`;

const CartInfo = styled.div`
  text-align: center;
  align-items: center;
  background-color: ${(props) =>
    props.qtyValue === 0 ? "transparent" : colors.lightcolor};
  color: white;
  font-size: 14px;
  font-weight: 200;
  margin-bottom: 10px;
`;
const DeliveryMethodDiv = styled.div`
  align-items: center;
  width: 20rem;
  & div:hover {
    background-color: ${colors.primaryColor};
  }
`;
const WaitDiv = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & p {
    margin-top: 0.8rem;
  }
  & h3 {
    text-align: center;
    margin-top: 0.8rem;
    color: ${colors.primaryColor};
  }
`;
const DeliveryMethodButton = styled.div`
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 1px;
  align-items: center;
  text-align: center;
  color: ${colors.whiteColor};
  margin: 1rem;
  padding: 5px;
  margin-top: 5px;
  background-color: ${colors.lightcolor};
`;
//001 ++ Ürünlere favori seçimi eklenerek headera favoriler sekmesi eklenecek.
const FavoriteInfo = styled.div`
  color: ${colors.primaryColor};
  cursor: pointer;
`;
//001 -- Ürünlere favori seçimi eklenerek headera favoriler sekmesi eklenecek.
//#endregion

function CardContent(props) {
  //#region VARIABLES
  const [qtyValue, setqtyValue] = useState(0);
  const [accordionData, setAccordionData] = useState([]);
  const [productCategory, setProductCategory] = useState({});
  const [productSubCategory, setProductSubCategory] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const [disableValue, setDisabled] = useState(false);
  const inputRef = useRef(null);

  const [delMethodQty, setDelMethodQty] = useState(0);
  const [deliveryMethod, setDeliveryMethod] = useState("");

  const [unit, setUnit] = useState("");
  const [units, setUnits] = useState([]);
  const [collectionPrice, setCollectionPrice] = useState(0);
  const [itemPrice, setItemPrice] = useState(0);
  const [deliveryPrice, setDeliveryPrice] = useState(0);

  const [open, setOpen] = useState(false);
  //001 ++ Ürünlere favori seçimi eklenerek headera favoriler sekmesi eklenecek.
  const [favorite, setFavorite] = useState(props.product.favorite);
  //001 -- Ürünlere favori seçimi eklenerek headera favoriler sekmesi eklenecek.
  //002 ++ 'Offer Ends Bilgisi Gösterme.'
  const [offerEndDate, setOfferEndDate] = useState("");
  //002 -- 'Offer Ends Bilgisi Gösterme.'
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    disableValue && setDisabled(false);
  };
  let addProductMethod = "";
  //#endregion

  useEffect(() => {
    initialUnit(
      unit,
      props.product,
      setUnits,
      setUnit,
      initialPrice,
      setQunatityFunc,
      props.cart
    );
    if (props.categories.length > 0) {
      let cartItem = props.cart.find((c) => c.itemno === props.product.itemno);
      props.cart.length > 0
        ? setDeliveryMethod(props.cart[0].deliverymethod)
        : setDeliveryMethod("");
      if (cartItem) setqtyValue(cartItem.quantity);
      else setqtyValue(0);
      let l_accordionData = [];
      l_accordionData.push({
        parentid: 0,
        title: "Other Informations",
        content: [
          `description : ${props.product.description} - ${props.product.description2}`,
          `item category : ${props.product.itemcategory} `,
          `pack size : ${props.product.packsize} `,
          `unit size : ${props.product.unitsize} `,
          `sales unit : ${props.product.salesunit} `,
        ],
      });
      setAccordionData(l_accordionData);
      if (props.product.itemcategory !== "") {
        let cat = props.categories.find(
          (x) => x.code === props.product.itemcategory
        );
        setProductCategory(cat);
      }
      if (props.product.productgroup !== "") {
        let subCat = props.categories.find(
          (x) => x.code === props.product.productgroup
        );
        setProductSubCategory(subCat);
      }
      setLoading(false);
    }
  }, [props]);
  const initialPrice = async (unit, l_units, currentQuantity) => {
    if (props.product.weboffer > 0) {
      setCollectionPrice(props.product.weboffer);
      setDeliveryPrice(props.product.weboffer);
    } else {
      // #region new price calculation
      const priceGroupPrices = JSON.parse(
        props.product.prices.toString()
      ).filter(
        (p) =>
          p.unit === unit &&
          p.salestype === "Customer Price Group" &&
          p.salescode === props.currentUser.customerpricegroup
      );
      const customerPrices = JSON.parse(props.product.prices.toString()).filter(
        (p) =>
          p.unit === unit &&
          p.salestype === "Customer" &&
          p.salescode === props.currentUser.customerno
      );
      if (
        (customerPrices.length > 0 || priceGroupPrices.length > 0) &&
        props.currentUser.customerpricegroup !== "WHOLESALER"
      ) {
        let l_CustomerPrice = 0;
        let l_PriceGroupPrice = 0;
        await Promise.all(
          customerPrices.map((customerPrice) => {
            if (
              customerPrice.minqty === 0 ||
              customerPrice.minqty <= currentQuantity
            ) {
              if (
                l_CustomerPrice > customerPrice.unitprice ||
                l_CustomerPrice === 0
              )
                l_CustomerPrice = customerPrice.unitprice;
            }
          })
        );
        await Promise.all(
          priceGroupPrices.map((priceGroupPrice) => {
            if (
              priceGroupPrice.minqty === 0 ||
              priceGroupPrice.minqty <= currentQuantity
            ) {
              if (
                l_PriceGroupPrice > priceGroupPrice.unitprice ||
                l_PriceGroupPrice === 0
              )
                l_PriceGroupPrice = priceGroupPrice.unitprice;
            }
          })
        );
        if (l_CustomerPrice === 0 && l_PriceGroupPrice !== 0) {
          setCollectionPrice(l_PriceGroupPrice);
          setDeliveryPrice(l_PriceGroupPrice);
        } else if (l_CustomerPrice !== 0 && l_PriceGroupPrice === 0) {
          setCollectionPrice(l_CustomerPrice);
          setDeliveryPrice(l_CustomerPrice);
        } else if (l_CustomerPrice !== 0 && l_PriceGroupPrice !== 0) {
          if (l_CustomerPrice >= l_PriceGroupPrice) {
            setCollectionPrice(l_PriceGroupPrice);
            setDeliveryPrice(l_PriceGroupPrice);
          } else {
            setCollectionPrice(l_CustomerPrice);
            setDeliveryPrice(l_CustomerPrice);
          }
        } else {
          setCollectionPrice(0);
          setDeliveryPrice(0);
        }
      } else {
        let l_Collection = 0;
        await Promise.all(
          JSON.parse(props.product.prices.toString())
            .filter((x) => x.unit === unit)
            .map((price) => {
              if (
                price.salestype === "Customer Price Group" &&
                price.salescode === "COLLECTION" &&
                (price.minqty === 0 || price.minqty <= currentQuantity)
              ) {
                if (l_Collection > price.unitprice || l_Collection === 0)
                  l_Collection = price.unitprice;
              }
            })
        );
        l_Collection === 0
          ? setCollectionPrice(props.product.unitprice)
          : setCollectionPrice(l_Collection);
        setDeliveryPrice(props.product.unitprice);
      }

      // #endregion
    }
  };
  const getBestPrice = async (unit, currentQuantity, method) => {
    let returnPrice = 0;
    let g_collection_specialprice = 0;
    if (props.product.weboffer > 0) {
      returnPrice = props.product.weboffer;
    } else {
      props.product.specialprices !== "" &&
        (await Promise.all(
          JSON.parse(props.product.specialprices.toString()).map(
            async (specialprice) => {
              if (
                specialprice.unit === unit &&
                (specialprice.minqty === 0 ||
                  specialprice.minqty <= currentQuantity)
              ) {
                g_collection_specialprice = parseFloat(specialprice.unitprice);
              }
            }
          )
        ));
      let l_collectionPrice = 0;
      await Promise.all(
        JSON.parse(props.product.prices.toString())
          .filter((x) => x.unit === unit)
          .map((price) => {
            if (
              unit == price.unit &&
              (price.minqty === 0 || price.minqty <= currentQuantity)
            ) {
              // COLLECTION PRICE CONTROLU
              if (price.salestype === "All Customers") {
                if (
                  l_collectionPrice > price.unitprice ||
                  l_collectionPrice === 0
                )
                  l_collectionPrice = price.unitprice;
              }
              if (props.currentUser && props.currentUser.id) {
                if (
                  price.salestype === "Customer" &&
                  price.salescode === props.currentUser.customerno
                ) {
                  if (
                    l_collectionPrice > price.unitprice ||
                    l_collectionPrice === 0
                  )
                    l_collectionPrice = price.unitprice;
                } else if (
                  price.salestype === "Customer Price Group" &&
                  price.salescode === props.currentUser.customerpricegroup
                ) {
                  if (
                    l_collectionPrice > price.unitprice ||
                    l_collectionPrice === 0
                  )
                    l_collectionPrice = price.unitprice;
                }
              }
            }
          })
      );
      if (method === "Collection" || method === "Delivery") {
        if (g_collection_specialprice !== 0) {
          if (l_collectionPrice === 0) {
            returnPrice = g_collection_specialprice;
          } else {
            if (g_collection_specialprice <= l_collectionPrice) {
              returnPrice = g_collection_specialprice;
            } else {
              returnPrice = l_collectionPrice;
            }
          }
        } else {
          l_collectionPrice === 0
            ? (returnPrice = props.product.unitprice)
            : (returnPrice = l_collectionPrice);
        }
      }
    }

    return returnPrice;
  };
  const setQunatityFunc = (unit) => {
    let cartItem = props.cart.find(
      (c) => c.itemno === props.product.itemno && c.itemunit === unit
    );
    props.cart.length > 0
      ? setDeliveryMethod(props.cart[0].deliverymethod)
      : setDeliveryMethod("");
    if (cartItem) {
      setqtyValue(cartItem.quantity);
      initialPrice(unit, null, cartItem.quantity);
    } else {
      setqtyValue(0);
      initialPrice(unit, null, 0);
    }
  };
  const onButtonClickHandler = (quantity) => {
    const access_token = localStorage.getItem("access_token");
    if (access_token) {
      addProductMethod = "onClick";
      if (deliveryMethod === "") {
        setDelMethodQty(quantity);
        handleOpen();
      } else {
        addProductForButtonClick(quantity, deliveryMethod);
      }
    } else
      navigate("/route=account/login", {
        state: { href: window.location.href },
      });
  };
  const addProductForButtonClick = async (quantity, method) => {
    if (quantity === -1) {
      if (qtyValue > 1) {
        const newPrice = await getBestPrice(unit, qtyValue - 1, method);
        await props.actions.addCartApi({
          product: props.product,
          quantity: -1,
          deliveryMethod: method,
          selectedUnit: unit,
          price: newPrice,
          //price: method === "Collection" ? collectionPrice : deliveryPrice,
        });
      } else if (qtyValue === 1) {
        await props.actions.addCartApi({
          product: props.product,
          quantity: 0,
          deliveryMethod: method,
          selectedUnit: unit,
          price: method === "Collection" ? collectionPrice : deliveryPrice,
        });
      }
    } else {
      const newPrice = await getBestPrice(unit, qtyValue - 1, method);
      await props.actions.addCartApi({
        product: props.product,
        quantity: 1,
        deliveryMethod: method,
        selectedUnit: unit,
        price: newPrice,
      });
    }
  };
  const onChangeHandler = async (event) => {
    let name = event.target.name;
    let value = event.target.value;
    switch (name) {
      case "qtyinput":
        const access_token = localStorage.getItem("access_token");
        if (access_token) {
          addProductMethod = "onChangeHandler";
          if (value !== "") {
            let newQty = parseInt(value);
            let cartItem = props.cart.find(
              (c) => c.itemno === props.product.itemno
            );
            if (cartItem) {
              if (newQty === 0 || newQty < 0) {
                setDisabled(true);
                await props.actions.removeFromCart(cartItem.id);
                setDisabled(false);
                inputRef.current.focus();
              } else {
                setDisabled(true);
                await props.actions.updateCartItem({
                  cartItem: cartItem,
                  quantity: parseInt(newQty),
                });
                setDisabled(false);
                inputRef.current.focus();
              }
            } else {
              if (newQty > 0) {
                if (deliveryMethod === "") {
                  setDelMethodQty(parseInt(newQty));
                  handleOpen();
                } else {
                  addProductForOnChangeHandler(
                    parseInt(newQty),
                    deliveryMethod
                  );
                }
              } else {
                setqtyValue(0);
                setDisabled(false);
                inputRef.current.focus();
              }
            }
          } else {
            setqtyValue("");
          }
        } else
          navigate("/route=account/login", {
            state: { href: window.location.href },
          });

        break;
      default:
      // code block
    }
  };
  const addProductForOnChangeHandler = async (quantity, deliveryMethod) => {
    setDisabled(true);
    await props.actions.addCartApi({
      product: props.product,
      quantity,
      deliveryMethod,
      selectedUnit: unit,
      price: deliveryMethod === "Collection" ? collectionPrice : deliveryPrice,
    });
    setDisabled(false);
    inputRef.current.focus();
  };
  const changeDeliveryMethod = (method) => {
    addProductMethod === "onClick"
      ? addProductForButtonClick(delMethodQty, method)
      : addProductForOnChangeHandler(delMethodQty, method);
    handleClose();
  };
  //001 ++ Ürünlere favori seçimi eklenerek headera favoriler sekmesi eklenecek.
  const controlFavoriteProduct = async (value) => {
    const access_token = localStorage.getItem("access_token");
    if (access_token) {
      const body = {
        itemno: props.product.itemno,
        favorite: value,
      };
      await productActions.controlFavoriteProductApi(body, setFavorite);
      if (!value)
        props.refreshFavoriteList && (await props.refreshFavoriteList());
    } else {
      navigate("/route=account/login", {
        state: { href: window.location.href },
      });
    }
  };
  //001 -- Ürünlere favori seçimi eklenerek headera favoriler sekmesi eklenecek.
  return (
    <Container>
      {!loading ? (
        <Container>
          <OrcaModal isOpen={open} onClose={handleClose}>
            <DeliveryMethodDiv>
              <DeliveryMethodButton
                onClick={() => changeDeliveryMethod("Delivery")}
              >
                Delivery{" "}
              </DeliveryMethodButton>
              <DeliveryMethodButton
                onClick={() => changeDeliveryMethod("Collection")}
              >
                Collection{" "}
              </DeliveryMethodButton>
            </DeliveryMethodDiv>
          </OrcaModal>
          <OrcaModal isOpen={disableValue} onClose={() => setDisabled(false)}>
            <WaitDiv>
              <CircularProgress size={40} thickness={5} />
              <h3>PROCESS CONTINUING</h3>
              <p>Please wait ...</p>
            </WaitDiv>
          </OrcaModal>
          <Row>
            <BreadCrumbText
              onClick={() => {
                navigate("/");
              }}
            >
              HOME
            </BreadCrumbText>
            {productCategory.code && (
              <BreadCrumbText
                onClick={() => {
                  navigate("/route=search?categoryid=" + productCategory.code);
                }}
              >
                {productCategory.description}
              </BreadCrumbText>
            )}
            {productSubCategory.code && (
              <BreadCrumbText
                onClick={() => {
                  navigate(
                    `/route=search?categoryid=${productCategory.code}&productid=${productSubCategory.code}`
                  );
                }}
              >
                {" "}
                {productSubCategory.description}{" "}
              </BreadCrumbText>
            )}
            <BreadCrumbText style={{ color: "black" }}>
              {props.product.description}
            </BreadCrumbText>
          </Row>
          <CardRow>
            <CardRowDiv1>
              <ProductInfo type={props.product.weboffer > 0 ? "offer" : ""}>
                {props.product.weboffer > 0 ? "OFFER" : ""}
                <FavoriteInfo onClick={() => controlFavoriteProduct(!favorite)}>
                  {favorite ? (
                    <SelectedFavoriteIcon
                      sx={{ width: "1.7rem", height: "1.7rem" }}
                    />
                  ) : (
                    <FavoriteIcon sx={{ width: "1.7rem", height: "1.7rem" }} />
                  )}
                </FavoriteInfo>
              </ProductInfo>
              <Image src={`/${props.product.image}`} alt="cart_image" />
              <DDiv>
                <Details>
                  <Title>
                    {capitalizeFirstLetter(props.product.description)}
                  </Title>
                  <SubTitle>
                    {props.product.detaildescription.slice(1)}
                  </SubTitle>
                  <Text>
                    <h3>Product No : </h3> <span>{props.product.itemno}</span>{" "}
                  </Text>

                  {
                    //<Text><h3>Stock : </h3> <span>{props.product.stockqty && ((props.product.stockqty)<0 ? 0 : props.product.stockqty)}</span> </Text>
                    //props.settings.pricevisible
                  }
                  {true && props.currentUser.id && (
                    <PriceDiv>
                      <PriceDiv2>
                        <ColPrice deliveryMethod={deliveryMethod}>
                          Collection : £ {collectionPrice.toFixed(2)} - Incl.
                          VAT : £{" "}
                          {(
                            collectionPrice +
                            (collectionPrice * props.product.vat) / 100
                          )
                            .toFixed(2)
                            .toString()}
                        </ColPrice>
                        {itemPrice - collectionPrice > 0.05 && (
                          <OldPriceListView>
                            £{" "}
                            {(itemPrice + (itemPrice * props.product.vat) / 100)
                              .toFixed(2)
                              .toString()}
                          </OldPriceListView>
                        )}
                      </PriceDiv2>
                      <PriceDiv2>
                        <DelPrice deliveryMethod={deliveryMethod}>
                          Delivery: £ {deliveryPrice.toFixed(2)} - Incl. VAT : £{" "}
                          {(
                            deliveryPrice +
                            (deliveryPrice * props.product.vat) / 100
                          )
                            .toFixed(2)
                            .toString()}
                        </DelPrice>
                        {itemPrice - deliveryPrice > 0.05 && (
                          <OldPriceListView>
                            £{" "}
                            {(itemPrice + (itemPrice * props.product.vat) / 100)
                              .toFixed(2)
                              .toString()}
                          </OldPriceListView>
                        )}
                      </PriceDiv2>
                    </PriceDiv>
                  )}
                </Details>
                {qtyValue > 0 ? (
                  <CartActions>
                    <QtyInput
                      name="qtyinput"
                      onChange={onChangeHandler}
                      type="number"
                      value={qtyValue}
                      disabled={disableValue}
                      ref={inputRef}
                    />
                    <ButtonGroup
                      variant="outlined"
                      aria-label="outlined button group"
                    >
                      <Button
                        onClick={() => {
                          onButtonClickHandler(-1);
                        }}
                      >
                        <Remove
                          style={{
                            color: colors.primaryColor,
                            flex: "1",
                            height: "100%",
                          }}
                        />
                      </Button>
                      <Button
                        onClick={() => {
                          onButtonClickHandler(1);
                        }}
                      >
                        <Add
                          style={{
                            color: colors.primaryColor,
                            flex: "1",
                            height: "100%",
                          }}
                        />
                      </Button>
                    </ButtonGroup>
                  </CartActions>
                ) : (
                  <CartActions>
                    <AddToCartButton
                      onClick={() => {
                        onButtonClickHandler(1);
                      }}
                    >
                      <h3>Add To Cart</h3>
                      <ShoppingCartOutlined />
                    </AddToCartButton>
                  </CartActions>
                )}
              </DDiv>
            </CardRowDiv1>
            <CardRowDiv2>
              <ImageSlider type={"right"} />
            </CardRowDiv2>
          </CardRow>
          <Row></Row>
          <Carousel />
        </Container>
      ) : (
        <Container></Container>
      )}
    </Container>
  );
}
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      addCartApi: bindActionCreators(cartActions.addCart, dispatch),
      updateCartItem: bindActionCreators(cartActions.updateCartItem, dispatch),
      removeFromCart: bindActionCreators(cartActions.removeFromCart, dispatch),
    },
  };
}
function mapStateToProps(state) {
  return {
    cart: state.cartActionReducer,
    categories: state.categoryListReducer,
    settings: state.settingReducer,
    currentUser: state.currentUserReducer,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CardContent);
