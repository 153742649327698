import { useState, useEffect } from "react";
import {
  FiberManualRecordRounded,
  KeyboardArrowLeftOutlined,
  KeyboardArrowRightOutlined,
} from "@material-ui/icons";
import styled from "styled-components";
import { colors, API_URL } from "../res/values/values";
//#region Styles
const Container = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 1280px;
  margin-bottom: ${(props) => (props.type==='bottom' ? 2.8 : 0)}rem;
`;

const View = styled.div`
  display:flex ;
  flex:1 ;
  flex-direction:row ;
  background-color:transparent ;
  justify-content:center ;
  align-items:center ;
`;
const Image = styled.img`
  width: ${(props) => (props.page === "home" || props.page === "category" ? "100%" : "60%")};
  object-fit: contain;
  cursor:pointer;
  @media only screen and (max-width: 768px) {
    //height: 15rem;
    object-fit: contain;
  }
  
`;
const RightButton = styled.div`
  position: absolute;
  color: ${(props) => (props.color === "black" ? "#000" : "#000")};
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 1.5px;
  top: ${(props) => props.heightPosition}%;
  right: ${(props) => props.widthPosition}%;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.4);
  height: 35px;
`;
const LeftButton = styled.div`
  position: absolute;
  color: ${(props) => (props.color === "black" ? "#000" : "#000")};
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 1.5px;
  top: ${(props) => props.heightPosition}%;
  left: ${(props) => props.widthPosition}%;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.4);
  height: 35px;
`;
const Buttons = styled.div`
  position: absolute;
  bottom: 2%;
  left: 47%;
  display: flex;
`;
const Button = styled.div`
  color: ${(props) => (props.color === "black" ? colors.primaryColor : "gray")};
`;
//#endregion

function ImageSlider(props) {
  const [bannerImages, setBannerImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [timeId, setTimeId] = useState("");
  useEffect(() => {
    const getBanners = async () => {
      let url = `${API_URL}/banners`;
      await fetch(url)
        .then((res) => res.json())
        .then((res) => {
          let filteredList = (props.type === "home") ? 
          res.message.data.filter(x=>x.bannertype === 'SLIDER') : 
          (props.type === "category" ? res.message.data.filter(x=>x.bannertype === 'CATEGORY') :
          (props.type === "right" ? res.message.data.filter(x=>x.bannertype === 'RIGHT') : res.message.data.filter(x=>x.bannertype === 'LEFT')))
          setBannerImages(filteredList);
          setLoading(false);
        });
      if (timeId !== "") clearTimeout(timeId);
      let id = setTimeout(() => {
        if (bannerImages.length > 0) {
          if (selectedIndex === bannerImages.length - 1) setSelectedIndex(0);
          else setSelectedIndex(selectedIndex + 1);
        }
      }, 4000);
      setTimeId(id);
    };
    getBanners();
  }, [bannerImages.length, selectedIndex]);
  const rightSide = () => {
    if (bannerImages.length > 0) {
      if (selectedIndex === bannerImages.length - 1) setSelectedIndex(0);
      else setSelectedIndex(selectedIndex + 1);
    }
  };
  const leftSide = () => {
    if (bannerImages.length > 0) {
      if (selectedIndex === 0) setSelectedIndex(bannerImages.length - 1);
      else setSelectedIndex(selectedIndex - 1);
    }
  };
  return (
    <Container type={props.type}>
      {loading ? (
        <View />
      ) : bannerImages.length === 0 ? (
        <View>
          <Image page={props.type} src={`/catalog/Banners/logo.png`} />
        </View>
      ) : (
        <View>
          <Image page={props.type} src={`/${bannerImages[selectedIndex].link}`} />
          {props.type === "home" && (
            <LeftButton
              onClick={() => leftSide()}
              heightPosition={40}
              widthPosition={1}
            >
              <KeyboardArrowLeftOutlined fontSize="large" />
            </LeftButton>
          )}
          {props.type === "home" && (
            <RightButton
              onClick={() => rightSide()}
              heightPosition={40}
              widthPosition={1}
            >
              <KeyboardArrowRightOutlined fontSize="large" />
            </RightButton>
          )}
          {props.type === "home" && (
            <Buttons>
              {bannerImages.map((banner, index) => {
                return (
                  <Button
                    key={index}
                    onClick={() => {
                      setSelectedIndex(index);
                    }}
                    color={index === selectedIndex ? "black" : "white"}
                  >
                    <FiberManualRecordRounded />
                  </Button>
                );
              })}
            </Buttons>
          )}
        </View>
      )}
    </Container>
  );
}

export default ImageSlider;
