import styled from "styled-components";
import Carousel from "../Carousel";
import BrandsCarousel from "../BrandsCarousel";
const Container = styled.div`
  display:flex;
  flex-direction: column;
  justify-content:center;
  align-items:center;
 
  width: 100%;
`;
const CarouselContainer = styled.div`
  // background-color: #F4F6F8;
  width: 100%;
  display:flex;
  flex-direction: column;
  justify-content:center;
  align-items:center;
`;
const TitleContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1280px;
  width: 100%;
  margin-top: 2.813rem;
  margin-bottom: 2.188rem;
`;
const TitleLeft = styled.div`
  display: flex;
  flex-direction: column; 
`;
const Title = styled.span`
  color: #212B36;
  font-family: 'Roboto';
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media only screen and (max-width: 768px) {
    font-size: 1.2rem;
  }
`;
const SubTitle = styled.span`
  color: #D22424;
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media only screen and (max-width: 768px) {
    font-size: .85rem;
  }
`;
function HomeContent() {
  return (
    <Container>
      <CarouselContainer>
        <Carousel/>
      </CarouselContainer>
    </Container>
  );
}

export default HomeContent;