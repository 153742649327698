
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import  useWindowWidthAndHeight  from "../utilities/hooks/useWindowWidthAndHeight";

//#region STYLES

const Container = styled.div`
  display: flex;
  flex: 1;
  border: 1px solid lightgray;
  padding-bottom: 10px;
  flex-direction: column;
  justify-content: space-between;
  @media only screen and (min-width: 769px) {
    flex-direction: row;
  }
  @media only screen and (min-width: 992px) {
    flex-direction: row;
  }
  @media only screen and (min-width: 1200px) {
    flex-direction: row;
  }
`;
//#region Left
const Left = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
`;

const LogoContainer = styled.div`
  font-size: 60px;
  font-weight: 300;
  color: #333;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const LogoImage = styled.img`
  flex: 1;
  border-radius: 5px 5px 0 0;
  height: 4rem;
  width: 5.5rem;
  object-fit: contain;
  margin-top: 0.5rem;
  margin-left: 1rem;
`;
//#endregion
//#region center
const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SearchBar = styled.div`
  display: flex;
`;

//#endregion
//#region Right
const Right = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-right: 10px;
`;

const ButtonSection = styled.div`
  display: flex;
  align-items: center;
`;

const AccountInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.8rem;
  margin-left: 10px;
  letter-spacing: 1px;
  cursor: pointer;
  & a {
    color:black ;
    text-decoration-line:none;
  }
`;
//#endregion
//#endregion
function HeaderMaintence(props) {
  const navigate = useNavigate();
  const { width } = useWindowWidthAndHeight();
  return (
    <Container>
      <Left>
        <LogoContainer
          onClick={(_) => {
            navigate("/");
          }}
        >
          <LogoImage src={`/catalog/Products/logo.png`}></LogoImage>
        </LogoContainer>
      </Left>
      <Center>
      </Center>
      <Right>
      </Right>
    </Container>
  );
}
export default HeaderMaintence;
