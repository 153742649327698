import { useEffect, useState } from "react";
import { Badge } from "@material-ui/core";
import { Person, ShoppingBasket, Info } from "@material-ui/icons";
import Autocomplete from "./AutoComplete";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as cartActions from "../redux/actions/cartActions";
import useWindowWidthAndHeight from "../utilities/hooks/useWindowWidthAndHeight";
import MenuBar from "./MenuComponents/MenuBar";
import Announcement from "./Announcement";
//#region STYLES
const AnnouncementDiv = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #fff;
  width: 100%;
  position: sticky;
  top: 0px;
  z-index: 9900;
 
`;
const StickyContent = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  max-width: 1280px;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  position: ${(props) => (props.isSticky ? "relative" : "relative")};
  top: 0px;
  z-index: 10;
  box-shadow: ${(props) =>
    props.isScrolled
      ? "0 2px 0px rgba(0, 0, 0, 0.1)"
      : "0 0px 0px rgba(0, 0, 0, 0)"};
  opacity: ${(props) =>
    props.isSticky ? 1 : 1}; /* Başlangıçta görünmez olacak */
  transition: opacity 0.45s ease;
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  max-width: 1280px;
  padding-bottom: 0px;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (min-width: 769px) {
    flex-direction: row;
  }
  @media only screen and (min-width: 992px) {
    flex-direction: row;
  }
  @media only screen and (min-width: 1200px) {
    flex-direction: row;
  }
`;
//#region Left
const Left = styled.div`
  display: flex;
  flex:1;
  flex-direction: column;
  justify-content: center;
  
  padding: 2px;
  & h2 {
    color: #222222;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 100%;
    letter-spacing: 0.0015em;
  }
 & h3 {
    color: #222222;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
    letter-spacing: 0.0015em;
  }
  @media only screen and (max-width: 769px) {
    margin-top: .5rem;
    text-align: center;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  font-size: 60px;
  font-weight: 300;
  color: #333;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const LogoImage = styled.img`
  flex: 1;
  border-radius: 5px 5px 0 0;
  height: ${(props) => (props.isScrolled ? "3rem" : "5.2rem")};
  object-fit: contain;
  margin-top: 1rem;
  margin-bottom: 1rem;
  transition: height 0.3s ease;
`;
//#endregion
//#region center
const Center = styled.div`
  display: flex;
  flex:1;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 769px) {
    margin-bottom: 1rem;
  }
`;

const SearchBar = styled.div`
  
  display: flex;
  height: 2.85rem;
`;

//#endregion
//#region Right
const Right = styled.div`
  display: flex;
  flex:1;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

const ButtonSection = styled.div`
  display: flex;
  align-items: center;
`;

const AccountInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.8rem;
  margin-left: 10px;
  letter-spacing: 1px;
  cursor: pointer;
  & a {
    color: black;
    text-decoration-line: none;
  }
`;
const AccountInfoText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 0.8rem;
  margin-left: 10px;
  letter-spacing: 1px;
  cursor: pointer;
`;
const AccountInfoTest = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #00ab55;
  padding: 0.5rem;
  margin-left: 1rem;
`;
const AccountInfoTextTest = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 0.8rem;
  margin-left: 10px;
  letter-spacing: 1px;
  cursor: pointer;
  color: #fff;
`;
//#endregion
//#endregion
function NavBar(props) {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isSticky, setIsSticky] = useState(true);
  const [y, setY] = useState(0);
  const navigate = useNavigate();
  const { width } = useWindowWidthAndHeight();
  useEffect(() => {
    props.actions.getCartList();
  }, [props.actions]);
  useEffect(() => {
    const handleScroll = () => {
      setY(window.scrollY);
        if (y > (window.scrollY)) {
          setIsSticky(true);
        } else {
          setIsSticky(false);
        }
        
        if (window.scrollY > 0) {
          !isScrolled && setIsScrolled(true);
        } else {
          isScrolled && setIsScrolled(false);
        }
        
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [y]);
  return (
    <>
      <AnnouncementDiv>
        <Announcement />
      </AnnouncementDiv>
      <StickyContent isSticky={isSticky} isScrolled={isScrolled}>
        <Container>
          <Left>
          {width > 768 && (
            <>
              <h3>Welcome to the best online store </h3>
              <h2>6868 Food Service</h2>
            </>
        )}   
          </Left>
          <Center>
            <LogoContainer
                onClick={(_) => {
                  navigate("/");
                }}
              >
              <LogoImage
                isScrolled={isScrolled}
                src={`/catalog/Products/logo.png`}
              ></LogoImage>
            </LogoContainer>
          </Center>
          <Right>
            <SearchBar>
              <Autocomplete />
            </SearchBar>
          </Right>
        </Container>
        <MenuBar/>
      </StickyContent>
    </>
  );
}
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getCartList: bindActionCreators(cartActions.getCartList, dispatch),
    },
  };
}
function mapStateToProps(state) {
  return {
    cart: state.cartActionReducer,
    settings: state.settingReducer,
    currentUser: state.currentUserReducer,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
