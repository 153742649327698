import styled from "styled-components";
import ProductCardView from "./ProductCardView";
import TablePagination from "@mui/material/TablePagination";
import GridViewIcon from "@mui/icons-material/GridViewSharp";
import TableRowsSharpIcon from "@mui/icons-material/TableRowsSharp";
import { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as productActions from "../redux/actions/productActions";
import { colors } from "../res/values/values";
import useWindowWidthAndHeight from "../utilities/hooks/useWindowWidthAndHeight";
import CircularProgress from "@mui/material/CircularProgress";
import TablePaginationActions from "./PagesComponents/TablePaginationActions";
//#region Styles
const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 1rem 0.3rem;
  //align-items: center;
  min-height: 40vw;
  @media only screen and (max-width: 768px) {
    align-items: center;
  }
`;
const HasMoreContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const ProductList = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    justify-content: center;
  }
`;
const EmptyProductList = styled.div`
  width: 100%;
  text-align: center;
  font-size: 1rem;
  margin-top: 1rem;
  font-weight: 600;
  color: ${colors.primaryColor};
`;
const ListType = styled.div`
  display: flex;
  flex-direction: center;
  align-items: center;
  border: 1.5px solid lightgray;
  border-color: ${(props) => props.selected && colors.primaryColor};
  margin: 5px;
`;
const TablePaginationContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 95%;
`;
const ListTypeDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

//#endregion

function Products(props) {
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [changePageStatus, setChangePageStatus] = useState(false);
  const [listType, setListType] = useState("Grid");
  const { width } = useWindowWidthAndHeight();
  const observer = useRef();
  useEffect(() => {
    const renderPage = async () => {
      if (changePageStatus) setChangePageStatus(false);
      else {
        setPage(0);
        setRowsPerPage(20);
      }
      await props.actions.getProducts(
        props.categoryid,
        props.productid,
        props.filter,
        page,
        rowsPerPage,
        props.currentUser,
        props.brand,
        props.country
      );
      console.log(props.productCount);
      setLoading(false);
      setHasMore(false);
    };
    renderPage();
  }, [
    page,
    rowsPerPage,
    props.filter,
    props.productid,
    props.categoryid,
    props.brand,
    props.country,
  ]);
  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + window.scrollY >= document.body.offsetHeight - 1 &&
        !hasMore
      ) {
        //  && hasMore
        let pageCount = Math.floor(props.productCount / rowsPerPage);
        if (props.productCount % rowsPerPage === 0) pageCount = pageCount - 1;
        console.log(pageCount + " page count math floor");
        if (page < pageCount) {
          setChangePageStatus(true);
          setHasMore(true);
          setPage(page + 1);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [hasMore, page, props.productCount, rowsPerPage]);
  const handleChangePage = (event, newPage) => {
    setChangePageStatus(true);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setChangePageStatus(true);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  return props.products.length > 0 ? (
    <Container>
      <TablePaginationContent>
        <ListTypeDiv>
          <ListType
            selected={listType === "Grid"}
            onClick={() => setListType("Grid")}
          >
            <GridViewIcon sx={{ color: colors.darkcolor }} />
          </ListType>
          <ListType
            selected={listType === "List"}
            onClick={() => setListType("List")}
          >
            <TableRowsSharpIcon sx={{ color: colors.darkcolor }} />
          </ListType>
        </ListTypeDiv>

        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={props.productCount}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={""}
          ActionsComponent={TablePaginationActions} //
        />
      </TablePaginationContent>
      <ProductList>
        {props.products.map((p) => {
          return (
            <ProductCardView key={p.itemno} product={p} viewType={listType} />
          );
        })}
      </ProductList>
      {hasMore && (
        <HasMoreContainer>
          <CircularProgress
            style={{ marginTop: "20px" }}
            size={60}
            thickness={5}
          />
        </HasMoreContainer>
      )}
    </Container>
  ) : loading ? (
    <HasMoreContainer>
      <CircularProgress style={{ marginTop: "20px" }} size={60} thickness={5} />
    </HasMoreContainer>
  ) : (
    <EmptyProductList>
      Sorry, there were no results matching your search or filter.
    </EmptyProductList>
  );
}
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getProducts: bindActionCreators(productActions.getProducts, dispatch),
      getFilterProducts: bindActionCreators(
        productActions.getFilterProducts,
        dispatch
      ),
      clearProducts: bindActionCreators(
        productActions.clearProductsSuccess,
        dispatch
      ),
    },
  };
}

function mapStateToProps(state) {
  return {
    products: state.productListReducer,
    search: state.changeSearchReducer,
    productCount: state.productCountReducer,
    currentUser: state.currentUserReducer,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Products);
