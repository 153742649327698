import styled from "styled-components";
import ProductCardView from "./../ProductCardView";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as productActions from "../../redux/actions/productActions";
import { colors } from "../../res/values/values";
import useWindowWidthAndHeight from "../../utilities/hooks/useWindowWidthAndHeight";
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from "react-router-dom";
//#region Styles
const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 1rem 0.3rem;
  align-items: center;
  min-height: 40vw;
`;
const ProductList = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    justify-content: center;
  }
`;
const EmptyProductList = styled.div`
  font-size: 1rem;
  font-weight: 600;
  color: ${colors.primaryColor};
`;

//#endregion

function MyFavorites(props) {
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(parseInt(props.page));

  const [rowsPerPage, setRowsPerPage] = useState(parseInt(props.rowsPerPage));
  const [listType, setListType] = useState('Grid');
  const { width } = useWindowWidthAndHeight();
  const navigate = useNavigate();
  useEffect(() => {

    const renderPage = async () => {
      
     // if (changePageStatus) setChangePageStatus(false);
     // else {
     //   setPage(0);
     //   setRowsPerPage(10);
     // }
      await props.actions.getProducts(
        null,
        null,
        'myfavorites',
        0,
        1000,
        props.currentUser,
        null,
        null
      );

      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      setLoading(false);
    };
    //setLoading(true)
    renderPage();
    return () => {
      //setLoading(true);
      //props.actions.clearProducts();
    };
  }, [
  ]);

  const refreshFavoriteList =async ()=>{
    await props.actions.getProducts(
      null,
      null,
      'myfavorites',
      0,
      1000,
      props.currentUser,
      null,
      null
    );

  }

  return (
    <Container>
      {loading ? (
        <Container>
          <CircularProgress
                  size={40}
                  thickness={4}
            
                />
        </Container>
      ) : props.products.length > 0 ? (
        <Container>
    
          <ProductList>
            {props.products.map((p) => {
              return (
                <ProductCardView
                  key={p.itemno}
                  product={p}
                  viewType={listType}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  refreshFavoriteList={refreshFavoriteList}
                />
              );
              
            })}
          </ProductList>
  
        </Container>
      ) : (
        <EmptyProductList>
          Sorry, there were no results matching your search or filter.
        </EmptyProductList>
      )}
    </Container>
  );
}
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getProducts: bindActionCreators(productActions.getProducts, dispatch),
      getFilterProducts: bindActionCreators(
        productActions.getFilterProducts,
        dispatch
      ),
      clearProducts: bindActionCreators(
        productActions.clearProductsSuccess,
        dispatch
      ),
    },
  };
}

function mapStateToProps(state) {
  return {
    products: state.productListReducer,
    search: state.changeSearchReducer,
    productCount: state.productCountReducer,
    currentUser: state.currentUserReducer,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(MyFavorites);
