import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as categoryActions from "../../redux/actions/categoryActions";
import * as countryActions from "../../redux/actions/countryActions";
import { ArrowBack, Close } from "@material-ui/icons";
import { colors } from "../../res/values/values";
import useWindowWidthAndHeight from "../../utilities/hooks/useWindowWidthAndHeight";
import { capitalizeFirstLetter } from "../../utilities/helpers";
import styled from "styled-components";
const Content = styled.div`
  height: 100%;
  min-height: 72vh;
  max-height: 72vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: ${colors.primaryColor};
  font-size: 0.8rem;
  font-weight: 500;
`;
const ScrollDiv = styled.div`
  overflow-y: scroll;
  white-space: nowrap;
`;
const Element = styled.div`
  list-style: none;
  cursor: pointer;
  & a {
    color: #212b36;
    font-family: "Roboto";
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-inline-start: .4rem;
    margin-top: .5rem;
  }
`;
function MobileMenuBarContent(props) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [openIndex, setOpenIndex] = useState(-1);
  useEffect(() => {
    const getDatas = async () => {
      await props.actions.getCountryList();
      await props.actions.getCategories();
      setLoading(false);
    };
    getDatas();
  }, []);
  const handleLinkClick = (event, cat) => {
    event.preventDefault();
    props.close();
    navigate('/route=search?categoryid='+cat.code)
  };
  return (
    <Content>
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        flexDirection: "row",
        backgroundColor: "#fff",
        borderBottom: "1px solid #ddd",
        padding: "0.6rem",
        alignItems: "flex-end",
      }}
    >
      <Close
        style={{ color: "red" }}
        onClick={() => props.close()}
        color="disabled"
      />
    </div>
    
    <ScrollDiv>
      
    {props.categories
      .filter((cat) => cat.indentation === "ItemCategory")
      .map((cat,index) => {
        return (
          <Element key={index}>
            
            
                <a
                href={"/product-categories/" + cat.seodescription}
                onClick={(event) => handleLinkClick(event, cat)}
                title={cat.seodescription}
              >
                {capitalizeFirstLetter(cat.description)}
              </a>
                
          </Element>
        );
      })}
       
    </ScrollDiv>
    <Element
    
      onClick={() => {
        props.close();
        navigate("/route=search?filter=weboffers");
      }}
    >
      <a>Offers</a>
    </Element>
  </Content>
  );
}
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getCategories: bindActionCreators(
        categoryActions.getCategories,
        dispatch
      ),
      getCountryList: bindActionCreators(
        countryActions.getCountryList,
        dispatch
      ),
    },
  };
}

function mapStateToProps(state) {
  return {
    categories: state.categoryListReducer,
    countryList: state.countryReducer,
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileMenuBarContent);
