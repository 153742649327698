import React, { useEffect,useState } from "react";
import styled from "styled-components";
import {colors} from "../../res/values/values"
import { capitalizeFirstLetter, splitArray } from "../../utilities/helpers";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as categoryActions from "../../redux/actions/categoryActions";
import ImageSlider from "../ImageSlider";
const AccountMenuDiv = styled.div`
  align-items: flex-start;
  text-align: start;
  display: flex;
  flex-direction: row;
  padding-inline: 1rem;
  
`;
const AccountMenuColumnDiv = styled.div`
  align-items: flex-start;
  display: flex;
  margin:1rem;
  flex-direction: column;
  width: 50%;

`;
const Test = styled.div`
  display: flex;
  align-items: center;
  min-height: 20vh;
  max-height:75vh ;
  margin-top: .5rem;
`;
const AccountButton = styled.a`
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 1px;
  align-items: center;
  color: ${colors.blackColor};
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: ${colors.secondryColor};
    text-decoration: underline;
  }
`;

function MenuBarCategoriesContent(props) {
  const navigate = useNavigate()
  const [list, setlist] = useState([])
  useEffect(() => {
    const getData = async () => {
      if (props.categories.length === 0){
        await props.actions.getCategories();
      }else{
        const splitArrayList = splitArray( props.categories.filter(cat=>cat.indentation==="ItemCategory"),8)
        setlist(splitArrayList)
      }
      
    }; 
    getData()
  }, [props.categories]);

  const handleLinkClick = (event, cat) => {
    event.preventDefault();
    navigate('/route=search?categoryid='+cat.code)
  };

  return (
    <AccountMenuDiv>
      {list.map(listItem =>{
        return(
        <AccountMenuColumnDiv>
            {
                  listItem.map((cat,index)=>{
                    return (
                        <AccountButton 
                          key={index}
                          href={'/route=search?categoryid='+cat.code}
                          onClick={(event) => handleLinkClick(event, cat)}
                          title={capitalizeFirstLetter(cat.description)}
                        >{capitalizeFirstLetter(cat.description)}</AccountButton>
                    )
                })
            } 
            
          </AccountMenuColumnDiv>
        )
      })}
      <Test><ImageSlider type={"category"}/></Test>
       
    </AccountMenuDiv>
  );
}
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getCategories: bindActionCreators(
        categoryActions.getCategories,
        dispatch
      ),
    },
  };
}

function mapStateToProps(state) {
  return {
    categories: state.categoryListReducer,
  };
}
export default connect(mapStateToProps,mapDispatchToProps)(MenuBarCategoriesContent) ;
