import { useEffect, useState } from "react";
import { Divider } from "@material-ui/core";
import { LocalOffer, LocalOfferOutlined, Menu } from "@material-ui/icons";
import styled from "styled-components";
import DropDownMenu from "./DropDownMenu";
import MenuBarCategoriesContent from "./MenuBarCategoriesContent";
import MenuBarCountriesContent from "./MenuBarCountriesContent";
import MobileMenuBarContent from "./MobileMenuBarContent";
import { colors } from "../../res/values/values";
import { useNavigate } from "react-router-dom";
import useWindowWidthAndHeight from "../../utilities/hooks/useWindowWidthAndHeight";
import { KeyboardArrowDownOutlined } from "@mui/icons-material";

const Container = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.primaryColor};
  padding: 10px;
  width: 100%;
  max-width: 1280px;
  position: relative;
`;
const ContainerMobile = styled.div`
  display: flex;
  align-items: center;
  width: 95%;
  background-color: ${colors.darkcolor};
  color: ${colors.whiteColor};
  padding: 10px;
  position: relative;
`;
const MenuItem = styled.div`
  font-size: 22px;
  font-weight: 300;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  cursor: pointer;
  & p {
    font-family: 'Roboto';
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    //border-bottom: 1px solid #e5e5e5;
  }
`;

function MenuBar() {
  const navigate = useNavigate();
  const { height, width } = useWindowWidthAndHeight();
  const [visibleCountryBar, setVisibleCountryBar] = useState(true);
  useEffect(() => {
  }, []);
  const renderMenuBarComponent = () => {
    let renderMenuBar;

    if (width > 480) {
      renderMenuBar = (
        <Container>
          <DropDownMenu
            top="2 rem"
            icon={<KeyboardArrowDownOutlined />}
            text="Categories"
            contentLinks={<MenuBarCategoriesContent />}
          />
          <Divider
            orientation="vertical"
            style={{ margin: "0 10px", background: "#f2f2f2" }}
          />
          
          <DropDownMenu
              top="2.28rem"
              icon={visibleCountryBar &&  <Menu /> }
              text={visibleCountryBar ? "COUNTRIES" : ""}
              contentLinks={
                <MenuBarCountriesContent  visible={setVisibleCountryBar}/>
              }
            />

          <Divider
            orientation="vertical"
            style={{ margin: "0 10px", background: "#f2f2f2" }}
          />
          <MenuItem
            onClick={() => {
              navigate("/route=search?filter=weboffers");
            }}
          >
            <LocalOfferOutlined style={{ paddingRight: "2px" }} /> <p>Offers</p> 
          </MenuItem>
          <Divider
            orientation="vertical"
            style={{ marginLeft: "10px", background: "#f2f2f2" }}
          />
        </Container>
      );
    } else {
      renderMenuBar = (
        <ContainerMobile>
          <DropDownMenu
            color="#212B36"
            fontSize="1rem"
            fontWeight="600"
            top="0rem"
            left="0rem"
            icon={<Menu />}
            text=""
            contentLinks={"mobileMenu"}
          />
        </ContainerMobile>
      );
    }

    return renderMenuBar;
  };
  return renderMenuBarComponent();
}

export default MenuBar;
