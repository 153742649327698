import MyAccount from "./pages/MyAccount";
import Search from "./pages/Search";
import Home from "./pages/Home";
import Intro from "./pages/Intro";
import NoPage from "./pages/NoPage";
import { Routes, Route } from "react-router-dom";
import Card from "./pages/Card";
import ShoppingCart from "./pages/ShoppingCart";
import Checkout from "./pages/Checkout";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Charge from "./pages/Charge";
import Maintenance from "./pages/Maintenance";
//#region REDUX
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActions from "./redux/actions/userActions";
import * as settingsActions from "./redux/actions/settingsActions";
import useWindowWidthAndHeight from "./utilities/hooks/useWindowWidthAndHeight";
import styled from "styled-components";
import { colors } from "./res/values/values";

import Fab from "@mui/material/Fab";
import UpIcon from "@mui/icons-material/KeyboardArrowUp";
import CookieIcon from '@mui/icons-material/Cookie';
import Header from "./components/Header";
import Footer from "./components/Footer";
import ImageSlider from "./components/ImageSlider";
import OrcaBottomModal from "./components/Modal/OrcaBottomModal";
//#region Styles
const Container = styled.div`
   display:flex;
   flex: 1;
   flex-direction: column;
   justify-content:space-around;
   align-items:center;
   
   font-family: "Roboto";
   background-color:#fff ;
`;
const Content = styled.div`
    display:flex;
   justify-content:center;
   align-items:flex-start;
   flex: 1;
   width: 100%;
   //max-width: 1280px;
   min-height: 40rem;
   //margin:1.5rem; 
   overflow: hidden;
`;
const Left = styled.div`
   background-color: green;
   width: 25%;
`;
const Right = styled.div`
   background-color: yellow;
   width: 75%;
`;
const LogoContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
`;
const LogoImage = styled.img`
  height: 5rem;
  width: 5rem;
  object-fit: contain;
`;
const Button = styled.div`
  border: none;
  padding: 15px;
  background-color: ${colors.primaryColor};
  color: white;
  margin-top: 10px;
  cursor: pointer;
  & a {
    color: white;
    text-decoration-line: none;
  }
`;
const FabContent = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
  justify-content:space-around;
  position: sticky;
  background-color: transparent;
  bottom: 0;
  z-index: 100;
`;
const CookieTextBlock = styled.div`
  height:20rem ;
  overflow-y:scroll ;
  display: block;
  flex-direction: column;
  
`;
const CookieModal = styled.div`
  display:flex ;
  flex-direction:column ;
  padding:2rem ;
  line-height: 24px;
  & p {
    font-size: 15px;
    margin: 10px 0 16px 0;
    font-weight:400 ;
    
  }
  & h3 {
    color: black;
    text-decoration-line: underline;
    font-weight:800 ;
    font-size:14 ;
    margin:5px ;
  }
  & h4 {
    color: black;
    text-decoration-line: underline;
    font-weight:700 ;
    font-size:14 ;
    margin-top:1.5rem ;
  }
  
`;
const CookieButtons = styled.div`
  display: flex;
  flex-direction:column ;
  flex: 1;
  justify-content: space-between;
  margin-top:2rem ;
  @media only screen and (min-width: 600px) {
    flex-direction: row ;
  }
`;
const CookieButtonActive = styled.div`
  display: flex;
  flex: 1;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
  justify-content:center ;
  align-items: center;
  border-width: 2px;
  border-style: solid;
  background-color: ${colors.primaryColor};
  border-color: #dddddd #dddddd #b3b3b3 #b7b7b7;
  color: #fff;
  border-radius: 2px;
  padding: 8px;
  margin-top: 5px;
  cursor: pointer;
  @media only screen and (min-width: 600px) {
    margin-right: 10px;
  }
  &:hover {
    background-color: ${colors.whiteColor};
    color: ${colors.darkcolor};
    border-color:${colors.darkcolor};
  }
`;
const CookieButtonPassive = styled.div`
  display: flex;
  flex: 1;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
  justify-content:center ;
  align-items: center;
  border-width: 2px;
  border-style: solid;
  background-color: ${colors.whiteColor};
  border-color: ${colors.primaryColor};
  color: ${colors.primaryColor};
  border-radius: 2px;
  padding: 8px;
  margin-top: 5px;
  cursor: pointer;
  @media only screen and (min-width: 600px) {
    margin-right: 10px;
  }
  &:hover {
    background-color: ${colors.primaryColor};
    color: ${colors.whiteColor};
  }
`;
const StoreLocationModal = styled.div`
  display:flex ;
  flex-direction:column ;
  padding:2rem ;
  line-height: 24px;
  & p {
    font-size: 15px;
    margin: 10px 0 16px 0;
    font-weight:400 ;
    
  }
  & h3 {
    color: black;
    text-decoration-line: underline;
    font-weight:800 ;
    font-size:14 ;
    margin:5px ;
  }
  & h4 {
    color: black;
    text-decoration-line: underline;
    font-weight:700 ;
    font-size:14 ;
    margin-top:1.5rem ;
  }
  
`;
//#endregion
const App = (props) => {
  const [loading, setLoading] = useState(true);
  const { height } = useWindowWidthAndHeight();

  const applePlatform = window.navigator.platform.match(/iP(ad|hone)/);
  const androidPlatform = window.navigator.platform.match(
    /Mobile|mini|Fennec|Android|Linux/
  );
  const [disableValue, setDisabled] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [open, setOpen] = useState(false);
  const [openCookies, setOpenCookies] = useState(false);
  const [openStoreLocation, setOpenStoreLocation] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    disableValue && setDisabled(false);
  };
  const handleCloseStoreLocation = () => {
    setOpenStoreLocation(false);
  };
  useEffect(
    (_) => {
      window.addEventListener("scroll", () => {
        if (window.pageYOffset > 300) {
          setShowButton(true);
        } else {
          setShowButton(false);
        }
      });
      
      const renderPage = async () => {
        await props.actions.getSettings();
        const access_token = localStorage.getItem("access_token");
        if (access_token) await props.actions.getUser();
        setLoading(false);
      };
      renderPage();
      
      //if (width <= 768) {
        //(androidPlatform || applePlatform) && handleOpen();
     // }
    },
    [props.actions]
  );
  const upPage = ()=>{
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  const renderHomePage = () =>{
    let homeComponent;
    loading ?
      homeComponent= (
        <Container />
      )
      :
      homeComponent = (
        <Container height={height}>
          <OrcaBottomModal isOpen={open} onClose={handleClose}>
              <LogoContainer>
                <LogoImage src={`/catalog/Products/logo.png`}></LogoImage>
                {applePlatform ? (
                  <Button>
                    <a href="https://apps.apple.com/tr/app/expo-foods-application/id1582599794">
                      Download My App
                    </a>
                  </Button>
                ) : (
                  androidPlatform && (
                    <Button>
                      <a href="https://play.google.com/store/apps/details?id=com.expofoodssalesapp">
                        Download My App
                      </a>
                    </Button>
                  )
                )}
              </LogoContainer>
            </OrcaBottomModal>
            <OrcaBottomModal
              isOpen={openCookies}
              onClose={() => {
                setOpenCookies(false);
              }}
            >
              <CookieModal>
                <h3>Customize Consent Preferences</h3>
                <CookieTextBlock>
                  <p>
                    We use cookies to help you navigate efficiently and perform
                    certain functions. You will find detailed information about
                    all cookies under each consent category below.
                  </p>
                  <p>
                    The cookies that are categorized as "Necessary" are stored
                    on your browser as they are essential for enabling the basic
                    functionalities of the site. ...{" "}
                  </p>
                  <p>
                    We also use third-party cookies that help us analyze how you
                    use this website, store your preferences, and provide the
                    content and advertisements that are relevant to you. These
                    cookies will only be stored in your browser with your prior
                    consent.
                  </p>
                  <p>
                    You can choose to enable or disable some or all of these
                    cookies but disabling some of them may affect your browsing
                    experience.
                  </p>

                  <h4>Necessary</h4>
                  <p>
                    Necessary cookies are required to enable the basic features
                    of this site, such as providing secure log-in or adjusting
                    your consent preferences. These cookies do not store any
                    personally identifiable data.
                  </p>

                  <h4>Functional</h4>
                  <p>
                    Functional cookies help perform certain functionalities like
                    sharing the content of the website on social media
                    platforms, collecting feedback, and other third-party
                    features.
                  </p>

                  <h4>Analytics</h4>
                  <p>
                    Analytical cookies are used to understand how visitors
                    interact with the website. These cookies help provide
                    information on metrics such as the number of visitors,
                    bounce rate, traffic source, etc.
                  </p>

                  <h4>Performance</h4>
                  <p>
                    Performance cookies are used to understand and analyze the
                    key performance indexes of the website which helps in
                    delivering a better user experience for the visitors.
                  </p>

                  <h4>Advertisement</h4>
                  <p>
                    {" "}
                    Advertisement cookies are used to provide visitors with
                    customized advertisements based on the pages you visited
                    previously and to analyze the effectiveness of the ad
                    campaigns.
                  </p>
                </CookieTextBlock>
                <CookieButtons>
                  <CookieButtonPassive
                    onClick={() => {
                      setOpenCookies(false);
                    }}
                  >
                    Reject All
                  </CookieButtonPassive>
                  <CookieButtonPassive
                    onClick={() => {
                      setOpenCookies(false);
                    }}
                  >
                    Save My Preferences
                  </CookieButtonPassive>
                  <CookieButtonActive
                    onClick={() => {
                      setOpenCookies(false);
                    }}
                  >
                    Accept All
                  </CookieButtonActive>
                </CookieButtons>
              </CookieModal>
            </OrcaBottomModal>
          
          <Header/> 
          <Content>
          <Routes>
                {props.settings.maintenancemode ? (
                  <Route path="/" element={<Maintenance />} />
                ) : props.settings.intropage ? (
                  <Route path="/" element={<Intro />} />
                ) : (
                  <Route path="/" element={<Home />} />
                )}
                <Route path="/route=shop/" element={<Home />} />
                <Route path="*" element={<NoPage />} />

                <Route path="/route=account/" element={<MyAccount />}>
                  <Route path=":page" element={<MyAccount />} />
                </Route>
                <Route path="/route=search" element={<Search />} />
                <Route path="/route=product/card" element={<Card />}>
                  <Route path=":productid" element={<Card />} />
                </Route>
                <Route path="/route=checkout/cart" element={<ShoppingCart />} />
                <Route path="/route=checkout/checkout" element={<Checkout />} />
                <Route path="/route=about" element={<About />} />
                <Route path="/route=contact" element={<Contact />} />
                <Route path="/route=charge" element={<Charge />} />
              </Routes>
          </Content>
          <Footer/>
          <FabContent>
          {
              (showButton) && 
              <Fab onClick={()=>{upPage()}}
              sx={{
                position: "absolute",
                bottom: 24,
                right: 24,
                bgcolor:colors.whiteColor,
              }}
              aria-label="up"
            >
              <UpIcon  sx={{
                color:colors.primaryColor,
               
              }}/>
            </Fab>
            }
           
            {
              (!openCookies) && 
              <Fab onClick={()=>{setOpenCookies(true)}}
              sx={{
                position: "absolute",
                color:colors.primaryColor,
                bottom: 12,
                left: 12,
              }}
              color="default"
              aria-label="cookie"
            >
              <CookieIcon  sx={{
                color:colors.primaryColor,
               
              }}/>
              </Fab>
            }
            
          </FabContent>  
        </Container>
      )
      return homeComponent
  }
  return (
    renderHomePage()
  );
};
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getUser: bindActionCreators(userActions.getUser, dispatch),
      getSettings: bindActionCreators(settingsActions.getSettings, dispatch),
    },
  };
}
function mapStateToProps(state) {
  return {
    settings: state.settingReducer,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(App);
