import { Badge } from "@material-ui/core";
import { styled } from "@material-ui/core";
import { colors } from "../res/values/values";

export const RenderIf = ({ children, isTrue }) => {
  return isTrue ? children : null;
};
export const StyledBadge = styled(Badge)({
  "& .MuiBadge-badge": {
    color: "white",
    backgroundColor: colors.primaryColor,
    fontSize: 12,
    height: 24,
    minWidth: 24,
  },
});

export const customerDeliveryMethodControl = (cartList) => {
  return cartList.length > 0  ? true : false;
};
export const getFormattedDate = (date) => {
  try{
    const dateArray = date.split('T')
    const dateValues = dateArray[0].split('-')
    return `${dateValues[2]}/${dateValues[1]}/${dateValues[0]}`
  }catch(ex){
    return ''
  }
  
};
export const  validateInfo= (values) => {
  let errors = {};

  if (!values.name.trim()) {
    errors.name = 'Name required';
  }
  
  // else if (!/^[A-Za-z]+/.test(values.name.trim())) {
  //   errors.name = 'Enter a valid name';
  // }

  if (!values.email) {
    errors.email = 'Email required';
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'Email address is invalid';
  }
  if (!values.message.trim()) {
    errors.message = 'Message required';
  }
  /*
  if (!values.password) {
    errors.password = 'Password is required';
  } else if (values.password.length < 6) {
    errors.password = 'Password needs to be 6 characters or more';
  }

  if (!values.password2) {
    errors.password2 = 'Password is required';
  } else if (values.password2 !== values.password) {
    errors.password2 = 'Passwords do not match';
  }
  */
  return errors;
}
export const  validateMailInfo= (values) => {
  let errors = {};
  if (!values.email) {
    errors.email = 'Email required';
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'Email address is invalid';
  }
  return errors;
}
export const  loginValidateInfo= (values) => {
  let errors = {};
  if (!values.email) {
    errors.email = 'Email required';
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'Email address is invalid';
  }
  if (!values.password) {
    errors.password = 'Password is required';
  }
  return errors;
}
export const  registerValidateInfo= (values) => {
  let errors = {};
  if (!values.companyname) {
    errors.companyname = 'Company Name is required';
  }
  if (!values.address) {
    errors.address = 'Address is required';
  }
  if (values.address.length > 49) {
    errors.address = 'Address cannot be larger than 50 characters !';
  }
  if (values.address2.length > 49) {
    errors.address2 = 'Address 2 cannot be larger than 50 characters !';
  }
  if (values.postcode.length > 29) {
    errors.postcode = 'Post Code cannot be larger than 30 characters !';
  }
  if (!values.postcode) {
    errors.postcode = 'Post Code is required';
  }
  if (values.postcode.indexOf(' ') === -1) {
    errors.postcode = 'Post Code must contain space';
  }
  if (values.city.length > 29) {
    errors.city = 'City cannot be larger than 30 characters !';
  }
  if (!values.city) {
    errors.city = 'City is required';
  }
  if (!values.country) {
    errors.country = 'Country is required';
  }
  if (!values.firstname) {
    errors.firstname = 'First Name is required';
  }
  if (!values.lastname) {
    errors.lastname = 'Last Name is required';
  }
  if (!values.email) {
    errors.email = 'Email required';
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'Email address is invalid';
  }
  if (values.telephone.length > 29) {
    errors.telephone = 'Telephone cannot be larger than 30 characters !';
  }
  if (!values.telephone) {
    errors.telephone = 'Telephone is required';
  }else if (!/^\d+$/.test(values.telephone)) {
    errors.telephone = 'Telephone is invalid';
  }

  if (!values.password) {
    errors.password = 'Password is required';
  }else if (!/(?=.*[a-z])/.test(values.password)) {
    errors.password = 'Password must contain at least one lowercase letter.';
  }else if (!/(?=.*[A-Z])/.test(values.password)) {
    errors.password = 'Password must contain at least one uppercase letter.';
  }else if (!/(?=.*\d)/.test(values.password)) {
    errors.password = 'Password must contain at least one digit.';
  }else if (!/(?=.*[@$!%*?&])/.test(values.password)) {
    errors.password = 'Password must contain at least one special character (@$!%*?&).';
  }else if (!/.{8,}/.test(values.password)) {
    errors.password = 'Password must be at least 8 characters long.';
  }

  if (!values.passwordConfirm) {
    errors.passwordConfirm = 'Password Confirm is required';
  } else if (values.passwordConfirm !== values.password) {
    errors.passwordConfirm = 'Passwords do not match';
  }
  return errors;
}
export function getOperatingSystem() {
  let operatingSystem = 'Not known';
  if (window.navigator.appVersion.indexOf('Win') !== -1) { operatingSystem = 'Windows OS'; }
  if (window.navigator.appVersion.indexOf('Mac') !== -1) { operatingSystem = 'MacOS'; }
  if (window.navigator.appVersion.indexOf('X11') !== -1) { operatingSystem = 'UNIX OS'; }
  if (window.navigator.appVersion.indexOf('Linux') !== -1) { operatingSystem = 'Linux OS'; }

  return operatingSystem;
}
export const checkBlockedUnit = (product,unit) => {
  let blocked = true
  switch (unit.toString().toUpperCase()) {
    case 'EACH':
      blocked= product.blockedforeach; break;
    case 'BOX':
      blocked= product.blockedforbox; break; 
    case 'PACK':
      blocked= product.blockedforpack; break;
    case 'KG':
      blocked= product.blockedforkg; break;  
    case 'GR':
      blocked= product.blockedforgr; break;  
    default:
      blocked= false; break;
  }
  return blocked
}
export const initialUnit = (unit,product,setUnits,setUnit,initialPrice,setQunatityFunc,cart) => {
  if (unit === "") {
    let units = JSON.parse(product.units.toString());
    let productUnitList = [];
    units.map((unit) => {
      let unitValue = {
        code: unit.unit,
        qtyPerUnit: unit.qtyperunit,
        display:
          unit.unit !== product.baseunit
            ? `${unit.unit} ( ${unit.qtyperunit >= 1 ? parseInt(unit.qtyperunit.toString()) : unit.qtyperunit} x ${
                product.baseunit
              } )`
            : unit.unit,
      };
      productUnitList.push(unitValue);
    });
    setUnits(productUnitList);
    let cartItem = cart.find((c) => c.itemno === product.itemno);
    if (cartItem) {
      //initialPrice(cartItem.itemunit, productUnitList);
      setUnit(cartItem.itemunit);
      setQunatityFunc(cartItem.itemunit);
    } else {
      if(units.filter(u=>u.unit === product.salesunit).length > 0) {
        //initialPrice(product.salesunit, productUnitList);
        setUnit(product.salesunit);
      }else{
        //initialPrice(units[0].unit, productUnitList);
        setUnit(units[0].unit);
      }
      
      setQunatityFunc(product.salesunit);
    }
  } else {
    setQunatityFunc(unit);
  }
};
export const checkDeliveryDate = (method,user) => {
  if(user.deliveryDates.length>0){
    if (
      !user.deliveryDates[0].monday &&
      !user.deliveryDates[0].tuesday &&
      !user.deliveryDates[0].wednesday &&
      !user.deliveryDates[0].thursday &&
      !user.deliveryDates[0].friday &&
      !user.deliveryDates[0].saturday &&
      !user.deliveryDates[0].sunday &&
      method === "Delivery"
    ) {
      return false
    } else {
      return true
    }
  }else{
    return false
  }
  
};
export function capitalizeFirstLetter(str) {
  var words = str.split(' ');
  for (var i = 0; i < words.length; i++) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
  }
  return words.join(' ');
}
export function isFirstCharacterLetterOrDigit(str) {
  if (typeof str !== 'string' || str.length === 0) {
    return false;
  }
  const firstCharCode = str.charCodeAt(0);
  return (
    (firstCharCode >= 65 && firstCharCode <= 90) ||    // Büyük harfler (A-Z)
    (firstCharCode >= 97 && firstCharCode <= 122) ||   // Küçük harfler (a-z)
    (firstCharCode >= 48 && firstCharCode <= 57)      // Sayılar (0-9)
  );
}
export function splitArray(array, chunkSize) {
  var result = [];
  var numberOfChunks = Math.ceil(array.length / chunkSize);

  for (var i = 0; i < numberOfChunks; i++) {
      var start = i * chunkSize;
      var end = start + chunkSize;
      var subarray = array.slice(start, end);
      result.push(subarray);
  }

  return result;
}
export function changeAndAddMetaTags(metaDescription,metaTitle,link){
    const head = document.head;
    

    const existingCopyrightMeta= head.querySelector('meta[name="copyright"]');
    if (!existingCopyrightMeta) {
      const newMetaCopyright = document.createElement('meta');
      newMetaCopyright.name = 'copyright';
      newMetaCopyright.content = 'Orca Business Solutions';
      head.insertBefore(newMetaCopyright, head.firstChild) //appendChild(newMetaDescription);
    }
    
    const existingRobotsMeta= head.querySelector('meta[name="robots"]');
    if (existingRobotsMeta) {
      existingRobotsMeta.setAttribute('content', "index, follow");
    } else {
      const robotsMeta= document.createElement('meta');
      robotsMeta.name = 'robots';
      robotsMeta.content = "index, follow";
      head.insertBefore(robotsMeta, head.firstChild)
      
    }


    const existingMetaDescription = head.querySelector('meta[name="description"]');
    if (existingMetaDescription) {
      existingMetaDescription.setAttribute('content', metaDescription);
    } else {
      const newMetaDescription = document.createElement('meta');
      newMetaDescription.name = 'description';
      newMetaDescription.content = metaDescription;
      head.insertBefore(newMetaDescription, head.firstChild) //appendChild(newMetaDescription);
      
    }

    
    const canonicalLink = document.querySelector('link[rel="canonical"]');
    if (canonicalLink) {
      // Canonical link etiketini güncelleme
      canonicalLink.href = link;
    }else{
      const newLink = document.createElement('link');
      newLink.rel = 'canonical';
      newLink.href = `${link}`
      head.insertBefore(newLink, head.firstChild) //.appendChild(newLink);
    }

    
    
    document.title = metaTitle;
}
