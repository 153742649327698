import styled from "styled-components";
import useForm from "../../utilities/hooks/useForm";
import { validateInfo } from "../../utilities/helpers";
import { colors } from "../../res/values/values";
//#region Styles

const Container = styled.div``;
const DivContent = styled.div`
  width: 100%;
  margin: 1rem;
  @media only screen and (max-width: 768px) {
    width: 90%;
  }
`;
const ContactUsDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4rem;

  font-family: "Raleway", sans-serif;
  & p {
    color: black;
    font-family: "Poppins", sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 26px;
    text-decoration: none;
    display: block;
  }
  & h2 {
    margin-bottom: 2rem;
    color: ${colors.primaryColor};
    font-size: 42px;
    font-weight: 700;
    text-transform: capitalize;
  }
  & h4 {
    color: ${colors.primaryColor};
    font-size: 15px;
    line-height: 1.58em;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0;
  }
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
`;
const Input = styled.input`
  flex: 1;
  min-width: 40%;
  margin: 10px 0;
  padding: 10px;
  border: 1px;
  border-color: lightgray;
  border-style: solid;
  &:focus {
    outline: none !important;
    border-color: ${colors.primaryColor};
  }
`;
const MultiInput = styled.textarea`
  margin: 10px 0;
  padding: 10px;
  border: 1px;
  height: 20vw;
  border-color: lightgray;
  border-style: solid;
  text-align: start;
`;
const Button = styled.input`
  color: ${colors.whiteColor};
  font-size: 15px;
  font-weight: 600;
  border: none;
  padding: 15px 20px;
  background-color: ${colors.primaryColor};
  opacity: 80%;
  margin-bottom: 10px;
`;

//#endregion
function ContactContent(props) {
  const { handleChange, handleSubmit, values, errors } = useForm(
    props.sendMail,
    validateInfo
  );
  return (
    <Container>
      <ContactUsDiv>
        <DivContent>
          <h2> Contact Us</h2>
          <h4>ADDRESS :</h4>
          <h4>THE GRID,UNIT 102 ,LOCKFIELD AVENUE ENFIELD,EN3 7PX,UNITED KINGDOM.</h4>
          <h4>For enquiries, call</h4>
          <h4>0208 0667155</h4>

          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2474.905044346218!2d-0.027462069836419335!3d51.6615749038093!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761e872109e165%3A0xc4e38db0f0e2c878!2s6868%20Fast%20Food%20Ltd!5e0!3m2!1sen!2str!4v1664981936789!5m2!1sen!2str"
            width="100%"
            height="400"
            style={{borderWidth:1,borderStyle:"solid",borderColor:"#ddd",marginTop:5}}
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </DivContent>
        <DivContent>
          <Form onSubmit={handleSubmit}>
            <Input
              name="email"
              onChange={handleChange}
              type="text"
              placeholder="email"
              value={values.email}
            />
            {errors.email && <p style={{ color: "red" }}>{errors.email}</p>}
            <Input
              name="name"
              onChange={handleChange}
              type="text"
              placeholder="name"
              value={values.name}
            />
            {errors.name && <p style={{ color: "red" }}>{errors.name}</p>}
            <MultiInput
              name="message"
              onChange={handleChange}
              type="text"
              placeholder="your message (optional)"
              value={values.message}
            />
            {errors.message && <p style={{ color: "red" }}>{errors.message}</p>}
            <Button type="submit" value="SUBMIT"></Button>
          </Form>
        </DivContent>
      </ContactUsDiv>
    </Container>
  );
}

export default ContactContent;
