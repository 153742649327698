
import styled from "styled-components";
import { colors } from "../../res/values/values";
//#region Styles

const Container = styled.div`
`;
const WhyUsDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #eaeaea !important ;
  padding: 40px 80px;
  min-height: 50vh;
  & p {
    color: black;
    font-family: "Poppins", sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 26px;
    text-decoration: none;
    display: block;
  }
  & h4 {
    line-height: 26px;
    text-align: start;
    color: ${colors.primaryColor};
    font-family: "Poppins", sans-serif;
    font-size: 42px;
    font-weight: 700;
    text-transform: capitalize;
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0;
  }
`;
const DivContent = styled.div`
  width: 50%;
  margin: 1rem;
  @media only screen and (max-width: 768px) {
    width: 90%;
  }
`;
//#endregion
function AboutContent() {

  return (
    <Container>
    
      <WhyUsDiv>
        <DivContent>
          <h4> Why Us ?</h4>
        </DivContent>
        <DivContent>
          6868 FAST FOOD LTD ABOUT CONTENT
        </DivContent>
      </WhyUsDiv>
    </Container>
  );
}

export default AboutContent;
