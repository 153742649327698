import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ImageSlider from "../components/ImageSlider";
import Carousel from "../components/Carousel";
import useWindowWidthAndHeight from "../utilities/hooks/useWindowWidthAndHeight";
import HomeContent from "../components/PagesComponents/HomeComponent";
const MainContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 100%;
  margin: .1rem;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
const Left = styled.div`
  width: 20%;
  margin-right: 1rem;
  @media only screen and (max-width: 768px) {
    width: 0;
    height:0;
  }
`;
const Right = styled.div`
  width: 100%;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Home = () => {
  const { height } = useWindowWidthAndHeight();
  useEffect(()=>{
    window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
  },[])
  return (
    <MainContainer>
      <Right>
      <Container height={height}>
        <ImageSlider type={"home"} />
        <HomeContent/>
      </Container>
      </Right>
    </MainContainer>
    
  );
};
export default Home;
