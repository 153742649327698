import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  KeyboardArrowLeftOutlined,
  KeyboardArrowRightOutlined,
} from "@material-ui/icons";
import styled from "styled-components";
import { colors, API_URL } from "../res/values/values";
import { useNavigate } from "react-router-dom";
import useWindowWidthAndHeight from "../utilities/hooks/useWindowWidthAndHeight";
import ProductCardView from "./ProductCardView";
//#region Styles
const BodyDiv = styled.div`
  display: grid;
  //flex: 1;
  //flex-direction:column;
  margin-top: 2.813rem;
  position: relative;
  overflow: hidden;
  max-width: 1280px;
  width: 100%;
  //margin: 5px 10px;
  //width:${(props) => props.width - 100}px;
`;
const Glider = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;

  //box-shadow: 0px 3px 15px -1px rgb(0 0 0 / 10%);
  padding: 10px 10px;
  background-color: white;
`;
const Glide = styled.div`
  height: auto;
  transition: 0.5s;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  @media only screen and (min-width: 380px) {
    min-width: 51%;
  }
  @media only screen and (min-width: 768px) {
    min-width: 33%;
  }
  @media only screen and (min-width: 1068px) {
    min-width: 25%;
  }
`;
const RightButton = styled.div`
  z-index: 2;
  position: absolute;
  color: ${(props) => (props.color === "black" ? "#000" : "#000")};
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 1.5px;
  top: ${(props) => props.heightPosition}%;
  right: ${(props) => props.widthPosition}%;
  background-color: #fff;
  border-radius: 50%;
  //box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.4);
  height: 35px;
  cursor: pointer;
`;
const LeftButton = styled.div`
  z-index: 2;
  position: absolute;
  color: ${(props) => (props.color === "black" ? "#000" : "#000")};
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 1.5px;
  top: ${(props) => props.heightPosition}%;
  left: ${(props) => props.widthPosition}%;
  background-color: #fff;
  border-radius: 50%;
  //box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.4);
  height: 35px;
  cursor: pointer;
`;

const ViewAllButton = styled.button`
  width: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid ${colors.primaryColor};
  font-size: 1.2rem;
  font-weight: 500;
  color: ${colors.primaryColor};
  padding: 10px;
  cursor: pointer;
  background-color: transparent;
  &:hover {
    background-color: ${colors.primaryColor};
    color: white;
    cursor: pointer;
  }
`;
const TitleContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;
`;
const TitleLeft = styled.div`
  display: flex;
  flex-direction: column;
`;
const Title = styled.span`
  color: #212b36;
  font-family: "Roboto";
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media only screen and (max-width: 768px) {
    font-size: 1.2rem;
  }
`;
const SubTitle = styled.span`
  color: #d22424;
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media only screen and (max-width: 768px) {
    font-size: 0.85rem;
  }
`;
const TitleViewAll = styled.span`
  color: #d22424;

  font-family: "Roboto";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-right: 1rem;
  margin-top: 1rem;
  @media only screen and (max-width: 768px) {
    font-size: 0.85rem;
  }
  &:hover {
    //background-color: ${colors.primaryColor};
    color: ${colors.darkcolor};
    cursor: pointer;
  }
`;

//#endregion
function Carousal(props) {
  const [weboffers, setWebOffers] = useState([]);
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const { height, width } = useWindowWidthAndHeight();
  useEffect(() => {
    const getWebOffers = async () => {
      let url = `${API_URL}/products/weboffers?customerprice=${props.currentUser.customerpricegroup}&customerno=${props.currentUser.customerno}`;
      await fetch(url)
        .then((res) => res.json())
        .then((res) => {
          setWebOffers(res.message.data);
        });
    };
    getWebOffers();
  }, [props.currentUser]);

  const moveBehind = () => {
    const viewSize = width < 380 ? 1 : width < 768 ? 2 : width < 1068 ? 3 : 4;
    value === -100 * (weboffers.length - viewSize)
      ? setValue(0)
      : setValue(value - 100);
  };
  const moveAhead = () => {
    const viewSize = width < 380 ? 1 : width < 768 ? 2 : width < 1068 ? 3 : 4;
    value === 0
      ? setValue(-100 * (weboffers.length - viewSize))
      : setValue(value + 100);
  };

  return weboffers.length > 0 ? (
    <BodyDiv>
      <TitleContent>
        <TitleLeft>
          <Title>Web Offers</Title>
          <SubTitle></SubTitle>
        </TitleLeft>

        <TitleViewAll
          onClick={() => navigate("/route=search?filter=weboffers")}
        >
          {" View All"}
        </TitleViewAll>
      </TitleContent>
      <LeftButton onClick={moveAhead} heightPosition={45} widthPosition={1}>
        <KeyboardArrowLeftOutlined fontSize="large" />
      </LeftButton>
      <RightButton onClick={moveBehind} heightPosition={45} widthPosition={1}>
        <KeyboardArrowRightOutlined fontSize="large" />
      </RightButton>

      <Glider width={width}>
        {weboffers.map((product, index) => {
          return (
            <Glide key={index} style={{ transform: `translateX(${value}%)` }}>
              <ProductCardView
                key={product.itemno}
                product={product}
                viewType={"Grid"}
              />
            </Glide>
          );
        })}
      </Glider>
    </BodyDiv>
  ) : (
    <></>
  );
}
function mapStateToProps(state) {
  return {
    currentUser: state.currentUserReducer,
  };
}
export default connect(mapStateToProps, null)(Carousal);
